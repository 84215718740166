.custom-right-arrow,
.custom-left-arrow {
  border: none;
  font-size: 20px;
  cursor: pointer;
  position: absolute;
  top: 25%;
  z-index: 99;
  border: 2px solid #f6b119;
  background: white;
  color: #f6b119;
  border-radius: 50%;
  width: 42px;
  height: 42px;
}

.custom-right-arrow::before {
  content: '❯';
  position: relative;
}

.react-multiple-carousel__arrow--left::before {
  content: "❮";
  position: relative;
}

.custom-right-arrow {
  right: 0;
}

.custom-left-arrow {
  left: 0;
}

.react-multi-carousel-list {
  position: inherit;
}

.react-multiple-carousel__arrow {
  border: 2px solid #f6b119;
  background: white;
}

.react-multiple-carousel__arrow::before {
  color: #f6b119;
}

.react-multiple-carousel__arrow--left {
  left: 3.5%;
}

/* .react-multiple-carousel__arrow--right {
  right: 3.5%;
} */

/*.carousel{
  text-align: center;
}
.carousel .thumbs{
  min-width: 700px !important;
  max-width: 750px !important;
  overflow-x: auto !important;
  transform: none !important;
}
.carousel-slider{
  background: #eaeaea;
  border-radius: var(--xlg-radius);
  overflow: hidden;
}
 .carousel.carousel-slider{
  width: 780px !important;
} 
.carousel .thumb{
  width: 125px !important;
  height: 125px;
  border-radius: var(--lg-radius);
}
.carousel .thumb.selected{
  border: 3px solid var(--secondary-color) !important;
}
.carousel .thumb:hover{
  border: 3px solid var(--secondary-color) !important;
}
.carousel .thumb img{
  height: 100%;
}
.carousel .slider-wrapper{
  width: 600px !important;
}
.carousel img{
  width: unset !important;
}
.carousel .slider-wrapper.axis-horizontal .slider{
  direction: ltr !important;
}
.carousel-img-container{
  height: 540px;
  line-height: 540px;
  text-align: center;
  overflow: hidden;
  padding: 0px 10px;
}
.carousel-img-container img{
  vertical-align: middle !important;
  height: 100% !important;
}*/