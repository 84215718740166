.inviteMolecule-input input{
    color: #272726 !important;
    -webkit-text-fill-color: unset !important;
    font-size: 18px !important;
}

.inviteMolecule-input input::placeholder{
    font-size: 16px !important;
    font-weight: bold !important;
    color: #848383 !important;
    -webkit-text-fill-color: unset !important;
    opacity: 1;
}
