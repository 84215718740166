.social-media-log {
  width: 200px;
  height: 60px;
  border: solid 1px rgba(39, 39, 38, 0.4) !important;
  border-radius: 10px !important;
  color: black !important;
  font-size: 22px;
  font-weight: 600;
  text-transform: none !important;
}

.orange-btn {
  width: 400px;
  height: 72px;
  background-color: #faa100 !important;
  border-radius: 10px !important;
  color: white !important;
  font-size: 24px !important;
  font-weight: bold !important;
  text-transform: none !important;
}

.orange-btn:hover {
  background-color: #D89708 !important;
}

.orange {
  background-color: #faa100 !important;
  color: white !important;
  font-weight: bold !important;
  text-transform: none !important;
}

.orange:hover{
  background-color: #D89708 !important;
}

.white-btn {
  width: 400px;
  height: 70px;
  font-size: 24px !important;
  font-weight: bold !important;
  color: #faa100 !important;
  border: solid 1px #faa100 !important;
  border-radius: 10px !important;
  text-transform: none !important;
}

.account-type-btn {
  width: 400px;
  height: 130px;
  border-radius: 10px !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #f6b119 !important;
}

.filter-btn {
  width: 100%;
  height: 44px;
  color: #272726 !important;
  font-size: 20px !important;
  justify-content: space-between !important;
  text-transform: none !important;
}

.date-btn {
  width: 193px;
  height: 40px;
  background-color: #f6b119 !important;
  border-radius: 20px !important;
  color: white !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  text-transform: none !important;
}

.book-btn {
  width: 100% !important;
  background: var(--primary-color) !important;
  height: 50px !important;
  border-radius: var(--md-radius) !important;
  color: #fff !important;
  font-weight: bold !important;
  font-size: 18px !important;
  text-transform: none !important;
}

.points-btn {
  height: 52px;
  width: 100%;
  font-size: 16px !important;
  font-weight: bold !important;
  border: solid 1px #f0b70d !important;
  border-radius: 8px !important;
  text-transform: none !important;
}

.profile-btn {
  height: 50px;
  width: 190px;
  font-size: 18px !important;
  font-weight: bold !important;
  border-radius: 8px !important;
  text-transform: none !important;
}

.delete-btn {
  height: 50px;
  width: 160px;
  font-size: 18px !important;
  font-weight: bold !important;
  border-radius: 8px !important;
  text-transform: none !important;
  color: #c21703 !important;
  border: solid 1px #cb2127 !important;
}

.delete-red-btn {
  height: 50px;
  width: 190px;
  font-size: 18px !important;
  font-weight: bold !important;
  border-radius: 8px !important;
  text-transform: none !important;
  color: white !important;
  background-color: #cb2127 !important;
}

.delete-modal-btn {
  height: 42px;
  width: 160px;
  font-size: 16px !important;
  font-weight: normal !important;
  border-radius: 8px !important;
  text-transform: none !important;
}

.unselected-btn {
  width: 133px !important;
  height: 48px !important;
  border-radius: 4px !important;
  background-color: #f9f9f9 !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  line-height: 1.1 !important;
  color: #b3b3b3 !important;
  text-transform: capitalize !important;
}

.selected-btn {
  width: 133px !important;
  height: 48px !important;
  border-radius: 4px !important;
  background-color: rgba(243, 147, 59, 0.1) !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  line-height: 1.1 !important;
  color: #f6b119 !important;
  text-transform: capitalize !important;
}

.cancel-red-btn {
  height: 56px;
  width: 100%;
  font-size: 20px !important;
  font-weight: bold !important;
  border-radius: 8px !important;
  text-transform: none !important;
  color: white !important;
  background-color: #df2525 !important;
}

.cancel-btn {
  height: 48px;
  width: 100%;
  border-radius: 4px;
  border: solid 1px #e7342d !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  color: #e7342d !important;
  text-transform: capitalize !important;
}

.pay-btn {
  height: 48px;
  width: 100%;
  border-radius: 4px;
  border: solid 1px #f6b119 !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  color: #f6b119 !important;
  text-transform: capitalize !important;
}

.download-qr {
  width: 150px;
  height: 42px;
  font-size: 14px !important;
  font-weight: bold !important;
  color: #faa100 !important;
  border: solid 1px #f0b70d !important;
  border-radius: 19px !important;
  text-transform: none !important;
}

.location-btn {
  width: 135px;
  height: 36px;
  border-radius: 3px !important;
  border: solid 1px #f6b119 !important;
  font-size: 14px !important;
  font-weight: bold !important;
  letter-spacing: 0.09px !important;
  color: #f6b119 !important;
  text-transform: none !important;
}

.responsive-btn {
  width: 280px !important;
  height: 60px !important;
  border-radius: 30px !important;
  background: var(--primary-color) !important;
  color: white !important;
}