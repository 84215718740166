.form-element {
    display: flex;
    flex-direction: column !important;
    gap: 17px;
    position: relative;
}

#sender_email, #description, #name{    
    border-radius: 10px;    
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    letter-spacing: normal;
    color: black;
    /* color: #848383; */
}

#sender_email, #description, #name, .form-element fieldset{
    border: none !important;
    outline: none;
    box-shadow: none;
}

#sender_email, #name{
    padding: 18px 14px;
}
#description{
    padding: 18px;
}

.form-element .MuiInputBase-fullWidth{
    padding: 0px!important;
}

.form-element .css-ittuaa-MuiInputAdornment-root{
    margin-right: 0px !important;
}

.form-element .css-1laqsz7-MuiInputAdornment-root{
    margin-left: 0px !important;
}

.form-element .MuiOutlinedInput-root{
    border-radius: 10px !important;
    border: none !important;
}


.form-element > button{
    font-size: 18px !important;
    font-weight: bold !important;
    line-height: 1.11;    
    padding: 11px 67px 10px !important;    
    margin: 21px auto 0px 0px !important;
    color: white !important;
    background-color: var(--primary-color) !important;
    border-radius: 8px;
    text-transform: capitalize !important;
}

.form-element > button.guest-contact{
    margin: 21px auto 0px !important;
}