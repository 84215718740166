.file-input {
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.upload-profile-pic {
    width: 32px;
    height: 32px;
    position: absolute;
    bottom: -12px;
    left: 32px;    
}

.upload-image {
    width: 80px;
    height: 80px;

}