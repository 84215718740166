.success-modal{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    max-width: 616px;
    margin: auto;
    padding: 3rem 1rem;
    object-fit: contain;
    border-radius: 10px;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none !important;    
    outline: none !important;
}


.success-modal > img{
    max-width: 100%;
    width: 33%;
    margin: 0rem auto 2rem;
}

.success-modal #modal-modal-title{
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: center;
    color: var(--font-color);
    margin-bottom: 2rem;
    padding-inline: 6rem;
}

.MuiModal-backdrop{
    opacity: 0.2 !important;
    background-color: #000 !important;
}

.success-modal button{
    font-size: 20px !important;
    font-weight: 800 !important;
    color: white !important;
    background-color: var(--primary-color) !important;
    border-radius: 8px;
    text-transform: capitalize !important;
    max-width: 60%;
    width: 20rem;
}