.type-btn {
    font-size: 28px !important;
    font-weight: bold !important;
    line-height: 1.43 !important;
    color: #aaaaaa !important;
    text-transform: capitalize !important;
    text-align: start !important;
}

.type-btn.Mui-selected{
    color: #272726 !important;
}

.MuiTabs-indicator {
    /* height: 2px !important; */
    border: solid 1px #f6b119;
}

/* .toggle-btns-container div {
    padding: 1rem;
}
.toggle-btns-container.appointments div{
    padding: 1px;
}
.toggle-btns-container.appointments button{
    padding: 15%;
    background: #fff;
}

.toggle-container div {
    padding-inline-end: 1rem;
    padding-top: 1rem;
}

.toggle-btn {
    background: #fff;
    border-radius: var(--lg-radius) !important;
    box-shadow: 0px 0px 5px 1px #ddd;
    min-height: 55px !important;
    display: inline-block !important;
    line-height: 1.5 !important;
    text-align: start !important;
    align-items: start !important;
}

.toggle-btn.Mui-selected {
    color: #fff !important;
    background: var(--secondary-color) !important;
}

.toggle-btn>.MuiTab-iconWrapper {
    margin-bottom: -8px !important;
    margin-left: 5px;
    margin-right: 5px;
    padding-top: 5px;
}

.switch-container {
    position: relative;
    width: max-content;
    height: 40px;
    margin: auto;
    border-radius: var(--xlg-radius);
    background: #f5f5f5;
    cursor: pointer;
}

.switch-btn {
    width: 190px;
    display: inline-block;
    text-align: center;
    color: #000;
    line-height: 2.5;
    z-index: 2;
    position: relative;
}

.switch-btn.Mui-selected {
    color: #fff !important;
    background: var(--secondary-color);
    border-radius: var(--xlg-radius);
    transition: all 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.MuiTabs-indicator {
    display: none;
}

.diagnosis-switch {
    width: max-content !important;
}

.settings-toggle-btn{
    height: 60px;
    width: 220px;
    text-align: center !important;
    align-items: center !important;
    margin-right: 5px !important;
    margin-left: 5px !important;
} */