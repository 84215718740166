.verif-code>div {
  width: 20rem !important;
  border-radius: 10px;
  background-color: #f9f9f9;
  padding-inline-start: 15px;
  margin: auto;
}

.verif-code>div>input {
  width: 33px !important;
  border: none !important;
  border-bottom: 2px solid grey !important;
  margin-left: 3%;
  margin-right: 3%;
  border-radius: 0 !important;
  background-color: #f9f9f9;
  margin-bottom: 10px;
}

.certificate-molecule {
  border: solid 1px #ececec;
  border-radius: 12px;
  padding: 18px;
}

/*.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
 */

 .basic-date-picker div div input{
  padding:14px !important
 }

 .edit-form div div input{
  padding: 16.5px 14px !important;
  width: 100% !important;
 }

 .edit-form .MuiFormControl-root fieldset{
  /* border-radius: 10px !important; */
  border: 1.5px solid transparent !important;

}