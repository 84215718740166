/* .datePicker{
    width: 100% !important;
    border-radius: 25px !important;
    height: 50px !important;
    padding: 0 20px !important; 
    box-sizing: border-box !important;
    border: 1px solid #969696;
}
.red-border{
    border: 1.5px solid #d32f2f;
}
.field-label{
    position: absolute;
    top: -12px;
    font-size: 13px;
    margin: 0 20px;
    background: #fff;
    z-index: 99;
    color: var(--secondary-color);
    padding: 0 10px;
}
.datepicker-container>div,
.datepicker-container>div>div,
.datepicker-container>div>div .react-datepicker__input-container{
    width: 100% !important;
}
.react-datepicker-popper{
    z-index: 999 !important;
}
.date-picker-icon{
    position: absolute;
    top: 15px;
    z-index: 99;
} */
.customTextField .MuiIconButton-root {
    visibility: visible !important;
  }