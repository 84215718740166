.book-panel{
    width: 100%;
    background: #fff;
    box-shadow: 1px 2px 8px 1px rgba(0, 0, 0, 0.15);
    border-radius: var(--md-radius);
}
.book-calender-input{
    width: 100%;
    border-radius: var(--lg-radius) !important;
    border: 1px solid #00000038 !important;
}

.book-calender{
    width: 100%;
    border-radius: var(--lg-radius) !important;
    padding: 2px;
}
.panels-container{
    border: 1px solid var(--border-color);
    background: #fff;
    border-radius: var(--lg-radius);
    padding: 20px;
}