.search-filter-input input#search{
    padding: 9.5px 4px 9.5px 5px !important;
}

.search-filter-input div[class$="menu"]{ z-index: 9 !important; }
.search-filter-input div[class$="control"]{
    box-shadow: none !important; 
    border-color: #cccccc !important;
}
.search-filter-input div[class$="control"]:focus,
.search-filter-input div[class$="control"]:focus-visible,
.search-filter-input div[class$="control"]:focus-within {
    border: 2px solid #f6b119 !important;
}

.search-filter-date.basic-date-picker{
    margin-top: 18px !important;
    margin-bottom: 18px !important;
}

.search-filter-date.basic-date-picker div div input {
    padding: 9.5px 14px !important;
}


.search-filter-spoken-lang > div > div.MuiInputBase-root.MuiAutocomplete-inputRoot{
    padding-top: 2px !important;
    padding-bottom: 2px !important;
}

.filter-btn {
    font-weight: bold !important;
}

.search-filter-date.basic-date-picker button.MuiButtonBase-root{
    top: 0.1rem !important;
}
    


.search-responsive .location-search .MuiInputAdornment-root button.MuiButtonBase-root{
    top: 4px !important;
}
.search-responsive .location-search > div > button.MuiButtonBase-root{
    top: 0.1rem !important;
}

.search-filter-date.basic-date-picker label.MuiFormLabel-root,
.search-responsive .location-search label.MuiFormLabel-root{
    top: -6px !important;
    font-weight: bold;
    color: #272726;
}


input::placeholder{
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #9d9d9d !important;
    opacity: 1 !important;
}


.search-filter-date .Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.23) !important;
}