

.terms-privacy-modal{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border: none;
    outline: none;
    border-radius: 16px;
    padding: 43px 33px !important;
    width: 870px !important;
    height: 651px !important;
}

.terms-privacy-modal.guest-contact-modal{
    width: 600px !important;
    height: 590px !important;
}

.terms-privacy-modal-container{
    height: 568px !important;
    flex-direction: row !important;
    overflow: auto !important;
}

.terms-privacy-modal .modal-close-icon{
    position: absolute;
    top: 20px;
    right: 29px;
    cursor: pointer;
}

.terms-privacy-modal .modal-head{
    color: #272726 !important;
    font-size: 38px !important;
    font-weight: 700 !important;
    margin-bottom: 27px !important;
    position: relative;
}

.terms-privacy-modal-divider {
    position: absolute;
    bottom: -10px;
    left: 7.5%;
    width: 85%;
    height: 2px;
    border-color: #F6B119 !important;
}

.terms-privacy-modal .modal-text{
    color: #2B2B2B !important;
    font-size: 18px !important;
    font-weight: 400 !important;
}

.faq-accordion::before{ display: none !important; }