
.expert_dive_trip .react-multi-carousel-list,
.expert_dive_course .react-multi-carousel-list{ 
    position: relative !important; 
}

.expert_dive_trip .react-multi-carousel-list .react-multiple-carousel__arrow,
.expert_dive_course .react-multi-carousel-list .react-multiple-carousel__arrow{ 
    top: 25% !important; 
}

.expert_dive_trip .react-multi-carousel-list .react-multiple-carousel__arrow--right,
.expert_dive_course .react-multi-carousel-list .react-multiple-carousel__arrow--right{
    right: 0% !important;
}
.expert_dive_trip .react-multi-carousel-list .react-multiple-carousel__arrow--left,
.expert_dive_course .react-multi-carousel-list .react-multiple-carousel__arrow--left{
    left: 0% !important;
}
