.ReactGridGallery {
  /* overflow: hidden !important; */
  width: 90% !important;
  margin: auto !important;
  height: 605px !important;
}
.ReactGridGallery_tile-viewport {
  width: 100% !important;
  height: 100% !important;
}
.ReactGridGallery_tile-viewport img {
  width: 100% !important;
  height: 100% !important;
  object-fit: fill;
  object-position: top;
}
.ReactGridGallery > div > div {
  width: 24% !important;
}
.ReactGridGallery > div > div:nth-of-type(1) {
  width: 50% !important;
}

.ReactGridGallery > div > div:nth-of-type(4) img {
  height: 100% !important;
  width: 100% !important;
}
.ReactGridGallery > div:nth-of-type(1) {
  width: 100% !important;
  height: 600px !important;
  overflow: hidden !important;
  text-align: center !important;
}
.ReactGridGallery > div:nth-of-type(1) > div:nth-of-type(4) img {
  height: 100% !important;
  width: 100% !important;
}
.ReactGridGallery > div:nth-of-type(5) .ReactGridGallery_tile-bottom-bar {
  top: 0 !important;
  background: rgba(0, 0, 0, 0.2) !important;
  text-align: center !important;
  line-height: 18 !important;
  max-height: 100% !important;
}

.ReactGridGallery_tile-bottom-bar {
  top: 0;
  z-index: 100;
}
.ReactGridGallery > div:nth-of-type(5) .ReactGridGallery_tile-bottom-bar span {
  font-size: 25px !important;
  font-weight: bolder !important;
  color: #fff !important;
  background: none !important;
}

@media only screen and (max-width: 600px) {
  .ReactGridGallery > div {
    width: 45% !important;
  }
}
