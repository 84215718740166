.nav-link{
    color:white;
    text-decoration: none;    
}

.selected-nav-link {
    color: #f6b119;
    text-decoration: none;
}

.ListItemText > span{ 
    font-size: 18px !important;
    font-weight: bold !important;     
}

.footer{
    /* min-height: 100vh;*/
    margin-top: auto; 
}

.footer h6.MuiTypography-root.MuiTypography-h6{
    font-size: 16px !important;
    font-weight: 400 !important;
}

.footer .nav-link{
    color: #848383 !important;
    font-size: 12px !important;
    font-weight: 500 !important;
}