.special-label {
    color: rgba(0, 0, 0, 0.6);
    font-family: Catamaran;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.4375em;
    padding: 0px 14px;
    user-select: none;
}

.phone-input-class:focus-visible{
    border: none !important;
    outline: none !important;
}

/* where focus input .special-label color = #f6b119 */
/* .special-label{
    color: #f6b119 !important;
} */