.destinations-carousel .react-multi-carousel-list,
.hotoffers-carousel .react-multi-carousel-list,
.diveTrips-carousel .react-multi-carousel-list,
.topRatedBranches-carousel .react-multi-carousel-list,
.courses-carousel .react-multi-carousel-list,
.liveaboards-carousel .react-multi-carousel-list {
    position: relative !important;
}

.destinations-carousel .react-multi-carousel-list .react-multiple-carousel__arrow {
    top: 45% !important;
}

.hotoffers-carousel .react-multi-carousel-list .react-multiple-carousel__arrow {
    top: 26% !important;
}

.courses-carousel .react-multi-carousel-list .react-multiple-carousel__arrow,
.diveTrips-carousel .react-multi-carousel-list .react-multiple-carousel__arrow {
    top: 28% !important;
}

.topRatedBranches-carousel .react-multi-carousel-list .react-multiple-carousel__arrow {
    top: 31% !important;
}

.liveaboards-carousel .react-multi-carousel-list .react-multiple-carousel__arrow {
    top: 24% !important;
}

.destinations-carousel .react-multi-carousel-list .react-multiple-carousel__arrow--right,
.hotoffers-carousel .react-multi-carousel-list .react-multiple-carousel__arrow--right,
.diveTrips-carousel .react-multi-carousel-list .react-multiple-carousel__arrow--right,
.topRatedBranches-carousel .react-multi-carousel-list .react-multiple-carousel__arrow--right,
.courses-carousel .react-multi-carousel-list .react-multiple-carousel__arrow--right,
.liveaboards-carousel .react-multi-carousel-list .react-multiple-carousel__arrow--right {
    right: 0% !important;
}

.destinations-carousel .react-multi-carousel-list .react-multiple-carousel__arrow--left,
.hotoffers-carousel .react-multi-carousel-list .react-multiple-carousel__arrow--left,
.diveTrips-carousel .react-multi-carousel-list .react-multiple-carousel__arrow--left,
.topRatedBranches-carousel .react-multi-carousel-list .react-multiple-carousel__arrow--left,
.courses-carousel .react-multi-carousel-list .react-multiple-carousel__arrow--left,
.liveaboards-carousel .react-multi-carousel-list .react-multiple-carousel__arrow--left {
    left: 0% !important;
}



@media screen and (max-width: 530px) {
    .search-input.home-search-input {
        max-width: 85% !important;
    }
}

@media screen and (max-width: 767.9px) {
    .explore-activities-image {
        height: 44.5px !important;
    }
}

@media screen and (max-width: 1195px) {
    .mobile-app-button {
        width: 50% !important;
    }
}


.sponsers-carousel .react-multiple-carousel__arrow--right {
    right: 5% !important;
}

.sponsers-carousel .react-multiple-carousel__arrow--left {
    left: 5% !important;
}

.sponsers-carousel ul.react-multi-carousel-track.addTrans {
    transition: all 5s linear !important;
}

.sponsers-carousel ul.react-multi-carousel-track.removeTrans {
    transition: all 1s linear 0s !important;
}

.sponsors .react-multi-carousel-track {
    align-items: center !important;
}